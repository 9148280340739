import React from 'react'
import { useState, useEffect, useMemo, useRef } from 'react';
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import AppraiserDashboardContext from '../../../components/reviewer/Dashboard/AppraiserDashboardContext';
import Timelinerevview from '../../../components/Dashboard_Reusable_Component/Timelinerevview';
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import {
    useGetEmployeeByFilterQuery,
    useGetlineManagerEmployeeQuery,
    useGetlineManagerPlusOneEmployeeQuery
} from "../../../service";
import { useGetNineboxQuery } from "../../../service/ninebox/ninebox";
import Potentialtalents from "../../../assets/Images/Potentialtalents.svg";
import Solidtalents from "../../../assets/Images/Solidtalents.svg";
import Star from "../../../assets/Images/Star.svg";
import Inconsistent from "../../../assets/Images/Inconsistent.svg";
import Solidperformers from "../../../assets/Images/Solidperformers.svg";
import HighPerformerstop from "../../../assets/Images/HighPerformerstop.svg";
import Lowperformers from "../../../assets/Images/Lowperformers.svg";
import Solidperformer from "../../../assets/Images/Solidperformer.svg";
import Highperformers from "../../../assets/Images/Highperformers.svg";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import CalendarFilters from '../../../components/Dashboard_Reusable_Component/calnderfilter';
import LineManagersTeamTable from './MyTeamTable/LineManagersTeamTable';
import PerformanceRatingandEmpRejectionofOverallDashboard from './GlobeChart/PerformanceRatingandEmpRejectionofOverallDashboard';
import TopPerformersExpandofRevOverallDashboard from '../../../components/homepage/OverallDashboardComponents/Topperformers/TopPerformersExpandofRevOverallDashboard';
import NineboxandTopPerformanceofReviewerOverallDashboard from '../../../components/homepage/OverallDashboardComponents/NineboxandTopperformance/NineboxandTopPerformanceofReviewerOverallDashboard';
import ExpandNineBoxandSolidtalentsofRevieweroverall from '../../../components/homepage/OverallDashboardComponents/NineBox/ExpandNineboxsolidtalentofRevieweroverall';
import OverallDashboardExpandViewofRevieweroverall from '../../../components/reviewer/Dashboard/teamtablereview/OverallDashboardExpanviewofRevieweroverall';
import GlobechartParentforoverallDashboard from './GlobeChart/GlobechartParentforoverallDashboard';
import FilteredTableofExpNineboxRevieweroverall from '../../../components/homepage/OverallDashboardComponents/NineBox/FiilterTableofEXPNineboxRevieweroverall';
import NineboxandTopPerformanceofNormalizerOverallDashboard from './NineBox/NineboxandTopPerformanceofNormalizerOverallDashboard';
import ExpandNineBoxandSolidtalentsofOverallDashboard from './ExpandNineBox/ExpandNineBoxandSolidtalentsofOverallDashboard';
import TopPerformersparentforOverallDashboard from './Topperformance/TopPerformersparentforOverallDashboard';
import NineboxparentforOveralldashboard from './NineBox/NineboxparentforOveralldashboard';
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const OverallDashboardForReviewer = (props: any) => {
    const {
        valueofTab,
        setTabsActive,
        activeCalenderName,
        setvalueOfActiveCalender,
        valueOfActiveCalender,
        employeeData1,
        appCalId,
        CalenderName,
        RatingsExpandActive,
        NineboxExpand,
        TopperformersExpandActive,
        TeamtableExpandActive,
        selectedRatingRange,
        solidperformersExpandActive,
        setRatingsExpandActive,
        setNineboxExpand,
        setTopperformersExpandActive,
        setselectedRatingRange,
        setTeamtableExpandActive,
        setSolidperformersExpandActive,
    } = props;
    const { data: user } = useLoggedInUser();
    const myRef = useRef(null);
    const navigate = useNavigate();

    //Query for lineManager Employee 
    const { data: lineManagerEmployee } = useGetlineManagerEmployeeQuery(user?.employee_code);
    const { data: lineManagerPlusOneEmployee } = useGetlineManagerPlusOneEmployeeQuery({
        employee_code: user?.employee_code,
        calendar_id: appCalId,
    });
    console.log(lineManagerEmployee,user?.employee_code, appCalId,"lineManager", lineManagerPlusOneEmployee)
    //Query for lineManager Employee
    const [lineManagerEmployeeData, setlineManagerEmployeeData] = React.useState<any>([]);
    const [lineManagerPlusOneEmployeeData, setlineManagerPlusOneEmployeeData] = React.useState<any>([]);
    const [DRandDRplus1, setDRandDRplus1] = React.useState<any>([]);
    //setting DR data-commented
    var Role = "Reviewer"
    //setting DR data
    //For Identifiction
    let navigationFrom = "Reviewer";
    let NAVIGATION_FROM = "OverallDashboard";
    let LOCATION_FROM = "ReviewerOverallDashboard";
    //For Identifiction
    const { data: RangeValue } = useGetNineboxQuery("");
    //function for nine box
    const [Range, setRange] = React.useState<any>([]);
    const [RangeHighFrom, setRangeHighFrom] = React.useState<any>(4);
    const [RangeHighTo, setRangeHighTo] = React.useState<any>(5);
    const [RangeMediumFrom, setRangeMediumFrom] = React.useState<any>(3);
    const [RangeMediumTo, setRangeMediumTo] = React.useState<any>(3.9);
    const [RangeLowFrom, setRangeLowFrom] = React.useState<any>(1);
    const [RangeLowTo, setRangeLowTo] = React.useState<any>(2.9);
    React.useEffect(() => {
        if (RangeValue?.data[0]?.performance_definitions !== undefined) {
            setRange(RangeValue?.data[0]?.performance_definitions)
            setRangeHighFrom(RangeValue?.data[0]?.performance_definitions?.high_from)
            setRangeHighTo(RangeValue?.data[0]?.performance_definitions?.high_to)
            setRangeMediumFrom(RangeValue?.data[0]?.performance_definitions?.medium_from)
            setRangeMediumTo(RangeValue?.data[0]?.performance_definitions?.medium_to)
            setRangeLowFrom(RangeValue?.data[0]?.performance_definitions?.low_from)
            setRangeLowTo(RangeValue?.data[0]?.performance_definitions?.low_to)
        }
    }, [RangeValue])
    const [page, setPage] = React.useState(0);
    //States for mulitiselect
    const [SectionFilter, setSectionFilter] = React.useState<string[]>([]);
    const [GradeFilter, setGradeFilter] = React.useState<string[]>([]);
    const [subSectionFilter, setSubSectionFilter] = React.useState<string[]>([]);
    const [talentCategoreyFilter, settalentCategoreyFilter] = React.useState<string[]>([]);
    const [talentCategoreyData, settalentCategoreyData] = React.useState<any>([]);
    //States for active components
    //States for ninebox data
    const [indexValue, setindexValue] = React.useState<any>('');
    const [indexBasedValue, setindexBasedValue] = React.useState<any>([]);
    const [indexBasedTitle, setindexBasedTitle] = React.useState<any>('');
    console.log(indexBasedValue, indexBasedTitle, "ibasedValues")
    const [low3Value, setLow3Value] = React.useState<any>([]);
    const [low4Value, setLow4Value] = React.useState<any>([]);
    const [low5Value, setLow5Value] = React.useState<any>([]);
    const [moderate3Value, setmoderate3Value] = React.useState<any>([]);
    const [moderate4Value, setmoderate4Value] = React.useState<any>([]);
    const [moderate5Value, setmoderate5Value] = React.useState<any>([]);
    const [high3Value, sethigh3Value] = React.useState<any>([]);
    const [high4Value, sethigh4Value] = React.useState<any>([]);
    const [high5Value, sethigh5Value] = React.useState<any>([]);
    //Nine box filtered data 
    //pass data as params
    const Low3 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeLowFrom && i?.normalizer.normalizer_rating <= RangeLowTo &&
                i?.appraisal?.potential == 'Low'
        });
        setLow3Value(temp)
        return temp;
    };
    const moderate3 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeLowFrom && i?.normalizer.normalizer_rating <= RangeLowTo &&
                i?.appraisal?.potential == 'Moderate'
        });
        setmoderate3Value(temp)
        return temp;
    };
    const high3 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeLowFrom && i?.normalizer.normalizer_rating <= RangeLowTo &&
                i?.appraisal?.potential == 'High'
        });
        sethigh3Value(temp)
        return temp;
    };
    const Low4 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeMediumFrom && i?.normalizer.normalizer_rating <= RangeMediumTo &&
                i?.appraisal?.potential == 'Low'
        });
        setLow4Value(temp)
        return temp;
    };
    const moderate4 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeMediumFrom && i?.normalizer.normalizer_rating <= RangeMediumTo &&
                i?.appraisal?.potential == 'Moderate'
        });
        setmoderate4Value(temp)
        return temp;
    };
    const high4 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeMediumFrom && i?.normalizer.normalizer_rating <= RangeMediumTo &&
                i?.appraisal?.potential == 'High'
        });
        sethigh4Value(temp)
        return temp;
    };
    const Low5 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeHighFrom && i?.normalizer.normalizer_rating <= RangeHighTo &&
                i?.appraisal?.potential == 'Low'
        });
        setLow5Value(temp)
        return temp;
    };
    const moderate5 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeHighFrom && i?.normalizer.normalizer_rating <= RangeHighTo &&
                i?.appraisal?.potential == 'Moderate'
        });
        setmoderate5Value(temp)
        return temp;
    };
    const high5 = (data: any) => {
        let temp = data?.filter((i: any) => {
            return i?.appraisal?.status == "completed" &&
                i?.normalizer.normalizer_rating >= RangeHighFrom && i?.normalizer.normalizer_rating <= RangeHighTo &&
                i?.appraisal?.potential == 'High'
        });
        sethigh5Value(temp)
        return temp;
    };
    //Nine box filter Function
    //chat gpt
    // const processedData = useMemo(() => {
    //     // Perform some expensive calculation here
    //      settalentCategoreyData(lineManagerEmployee?.getEmployee)
    //   }, [lineManagerPlusOneEmployee]);

    const processedData = useMemo(() => {
        let firstArray = lineManagerPlusOneEmployee?.lineManager?.filter((j: any) => j?.appraisal?.status != "excepted");
        let secondArray = lineManagerPlusOneEmployee?.lineManagerPlusOne?.filter((j: any) => j?.appraisal?.status != "excepted");
        let thirdLevelArray = lineManagerPlusOneEmployee?.lineManagerPlusTwo.filter((j: any) => j?.appraisal?.status != "excepted");
        let fourthLevelArray = lineManagerPlusOneEmployee?.lineManagerPlusThree.filter((j: any) => j?.appraisal?.status != "excepted");
        // let combinedArray = employeeData?.data?.filter((j: any) => j?.appraisal?.status != "excepted")
        //@ts-ignore
        let combinedArray = [];

        if (talentCategoreyFilter.includes("Level 1")) {
            //@ts-ignore
            combinedArray = combinedArray.concat(firstArray);
        }
        if (talentCategoreyFilter.includes("Level 2")) {
            combinedArray = combinedArray.concat(secondArray);
        }
        if (talentCategoreyFilter.includes("Level 3")) {
            combinedArray = combinedArray.concat(thirdLevelArray);
        }
        if (talentCategoreyFilter.includes("Level 4")) {
            combinedArray = combinedArray.concat(fourthLevelArray);
        }
        if (talentCategoreyFilter.length === 0) {
            combinedArray = combinedArray.concat(firstArray, secondArray, thirdLevelArray, fourthLevelArray);
        }
        const filteredArray = combinedArray?.filter((j: any) => j?.appraisal?.status != "excepted")
            ?.filter((item1: any) => {
                if (SectionFilter.length === 0) {
                    return item1
                } else { return !!SectionFilter?.find(item2 => item1.section === item2) }
            }).filter((item1: any) => {
                if (subSectionFilter.length === 0) {
                    return item1
                } else { return !!subSectionFilter?.find(item2 => item1.sub_section === item2) }
            })?.filter((item1: any) => {
                if (GradeFilter?.length === 0) {
                    return item1
                } else { return !!GradeFilter?.find(item2 => item1?.grade === item2) }
            });
        if (filteredArray?.length === 0) {
            settalentCategoreyData([]);
            Low3([]);
            moderate3([]);
            high3([]);
            Low4([]);
            moderate4([]);
            high4([]);
            Low5([]);
            moderate5([]);
            high5([]);
        } else {
            settalentCategoreyData(filteredArray);
            Low3(filteredArray);
            moderate3(filteredArray);
            high3(filteredArray);
            Low4(filteredArray);
            moderate4(filteredArray);
            high4(filteredArray);
            Low5(filteredArray);
            moderate5(filteredArray);
            high5(filteredArray);
        }
    }, [lineManagerPlusOneEmployee, SectionFilter, GradeFilter, subSectionFilter, talentCategoreyFilter]);
    //Main data use effect
    const dataforContext = useMemo(() => {
        if (RatingsExpandActive === false && TopperformersExpandActive === false
            && TeamtableExpandActive === false && NineboxExpand === false && solidperformersExpandActive === false
        ) {
            setTabsActive(false)
        } else {
            setTabsActive(true)
        }

    }, [RatingsExpandActive, TopperformersExpandActive, TeamtableExpandActive, NineboxExpand, solidperformersExpandActive])
    //Data for Ninebox
    const NineBoxValues = [
        {
            title: RangeValue?.data[0]?.box_9_definitions[0]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[0]?.definition,
            count: high3Value?.length,
            category: high3Value,
            color: "linear-gradient(to left, #F89994, #F7665E)",
            icon: <img src={Potentialtalents} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[1]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[1]?.definition,
            count: high4Value?.length,
            category: high4Value,
            color: "linear-gradient(to left, #71E1F6, #28B7D3)",
            icon: <img src={Solidtalents} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[2]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[2]?.definition,
            count: high5Value?.length,
            category: high5Value,
            color: "linear-gradient(to left, #71E1F6, #28B7D3)",
            icon: <img src={Star} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[3]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[3]?.definition,
            count: moderate3Value?.length,
            category: moderate3Value,
            color: "linear-gradient(to left, #F89994, #F7665E)",
            icon: <img src={Inconsistent} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[4]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[4]?.definition,
            count: moderate4Value?.length,
            category: moderate4Value,
            color: "linear-gradient(to left, #33CDB4, #079B82)",
            icon: <img src={Solidperformers} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[5]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[5]?.definition,
            count: moderate5Value?.length,
            category: moderate5Value,
            color: "linear-gradient(to left, #33CDB4, #079B82)",
            icon: <img src={HighPerformerstop} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[6]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[6]?.definition,
            count: low3Value?.length,
            category: low3Value,
            color: "linear-gradient(to left, #F89994, #F7665E)",
            icon: <img src={Lowperformers} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[7]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[7]?.definition,
            count: low4Value?.length,
            category: low4Value,
            color: "linear-gradient(to left, #F9C5A1, #F99B5B)",
            icon: <img src={Solidperformer} alt="image" />,
        },
        {
            title: RangeValue?.data[0]?.box_9_definitions[8]?.title,
            defenition: RangeValue?.data[0]?.box_9_definitions[8]?.definition,
            count: low5Value?.length,
            category: low5Value,
            color: "linear-gradient(to left, #F9C5A1, #F99B5B)",
            icon: <img src={Highperformers} alt="image" />,
        },
    ];
    //Data for Ninebox
    //For changing my actions's tab
    const [valueOfCardMyteam, setvalueOfCardMyteam] = React.useState<any>("");
    const handleOnClickCardMyteam = (e: any, title: any) => {
        setvalueOfCardMyteam(title)
        //@ts-ignore
        myRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    //For changing my actions's tab
    //functions for emp rej pie chart
    //In-Renormalization employee rejection chart on dashboard count
    const checkAppraisalStatusInProgressMyteam = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == true && item?.appraisal?.status === status;
        }).length;
    };
    //Completed employee rejection chart on dashboard count
    const checkAppraisalStatusCompletedMyteam = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.appraisal?.status === status &&
                (item?.employee?.employee_rejection === true);
        })?.length;
    };
    //in-Medaiation employee rejection chart on dashboard count
    const checkAppraisalinmediationStatusMyteam = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == false && item?.appraisal?.status === status;
        })?.length;
    };
    // expandableviews inprogress employee rejection chart
    const checkAppraisalStatusI = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == true && item?.appraisal?.status === status;
        });
    };
    // expandableviews completed employee rejection chart
    const checkAppraisalStatusC = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.appraisal?.status === status &&
                (item?.employee?.employee_rejection === true);
        });
    };
    // expandableviews inmediation employee rejection chart
    const checkAppraisalStatusIM = (status: string) => {
        return talentCategoreyData?.filter((item: any) => {
            return item?.employee?.employee_agree == false && item?.appraisal?.status === status;
        });
    };
    //functions for emp rej pie chart
    //function for doughnut chart
    const [range1Myteam, setrange1Myteam] = React.useState<any>(0);
    const [range2Myteam, setrange2Myteam] = React.useState<any>(0);
    const [range3Myteam, setrange3Myteam] = React.useState<any>(0);
    const [range4Myteam, setrange4Myteam] = React.useState<any>(0);
    const checklengthinRangeMyteam = (min: number, max: number) => {
        return (
            (talentCategoreyData?.filter((item: any) => {
                return (
                    item?.normalizer?.normalizer_rating >= min &&
                    item?.normalizer?.normalizer_rating <= max
                    && item?.appraisal?.status == "completed"
                );
            })?.length)
        );
    };
    // rating distribution expand views based on filter 
    const checklengthinRanges = (min: number, max: number) => {
        return (
            (talentCategoreyData?.filter((item: any) => {
                return (
                    item?.normalizer?.normalizer_rating >= min &&
                    item?.normalizer?.normalizer_rating <= max && item?.appraisal?.status == "completed"
                );
            }))
        );
    };
    useEffect(() => {
        setrange1Myteam(Math.round(checklengthinRangeMyteam(1, 2.49)))
        setrange2Myteam(Math.round(checklengthinRangeMyteam(2.5, 2.99)))
        setrange3Myteam(Math.round(checklengthinRangeMyteam(3, 3.99)))
        setrange4Myteam(Math.round(checklengthinRangeMyteam(4, 5)))

    }, [talentCategoreyData])
    //function for doughnut chart

    //function for status cards
    const checkAppraisalStatusforCardsMyteam = (status: string) => {
        if (talentCategoreyData)
            return talentCategoreyData?.filter((item: any) => {
                return item?.appraisal?.status === status;
            })?.length;
        else return 0;
    };
    const calculatePercentageforCardsMyteam = (num: number) => {
        if (talentCategoreyData && talentCategoreyData?.length != 0)
            return (num * 100) / talentCategoreyData?.length;
        else {
            return 0
        }
    };
    let CompletedEmpMyteam = checkAppraisalStatusforCardsMyteam("completed");
    let TotalEmpMyteam = checkAppraisalStatusforCardsMyteam("not-started") + checkAppraisalStatusforCardsMyteam("in-progress") +
        checkAppraisalStatusforCardsMyteam("normalized") + checkAppraisalStatusforCardsMyteam("rejected") + checkAppraisalStatusforCardsMyteam("completed")
    const StatusValuesMyteam = [
        {
            title: "Not-Started",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("not-started")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("not-started"),
            color: "linear-gradient(79deg, rgba(255,55,55,1) 39%, rgba(255,96,96,0.9136029411764706) 96%)"
        },
        {
            title: "In Progress",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("in-progress") + checkAppraisalStatusforCardsMyteam("normalized")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("in-progress") + checkAppraisalStatusforCardsMyteam("normalized"),
            color: "linear-gradient(to left, #F9C5A1, #F99B5B)"
        },
        {
            title: "Rejected",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("rejected")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("rejected"),
            color: "linear-gradient(to left, #BECFD9, #89949A)"
        },
        {
            title: "Completed",
            percentage: calculatePercentageforCardsMyteam(checkAppraisalStatusforCardsMyteam("completed")).toFixed(2),
            count: checkAppraisalStatusforCardsMyteam("completed"),
            color: "linear-gradient(to left, #35CFB6, #079B82)"
        },
    ];

    //function for status cards
    // function for Topperformers
    const TopPerformersOfAppraiserMyteam = talentCategoreyData?.slice()?.filter((f: any) => f?.reviewer?.reviewer_rating >= 4 && f?.appraisal?.status == "completed")
        ?.sort((a: any, b: any) => b?.reviewer?.reviewer_rating - a?.reviewer?.reviewer_rating)
    return (
        <>
            <div
                style={{ background: "#F1F1F1", minHeight: "100px", overflow: "hidden", height: "auto", }}
            >
                <AppraiserDashboardContext>
                    {(RatingsExpandActive === false && TopperformersExpandActive === false &&
                        TeamtableExpandActive === false && NineboxExpand === false && solidperformersExpandActive === false) && <div>
                            <div>
                                <CalendarFilters
                                    page={page}
                                    setPage={setPage}
                                    CalenderName={CalenderName}
                                    SectionFilter={SectionFilter}
                                    setGradeFilter={setGradeFilter}
                                    GradeFilter={GradeFilter}
                                    setSectionFilter={setSectionFilter}
                                    subSectionFilter={subSectionFilter}
                                    setSubSectionFilter={setSubSectionFilter}
                                    navigationFrom={navigationFrom}
                                    talentCategoreyFilter={talentCategoreyFilter}
                                    settalentCategoreyFilter={settalentCategoreyFilter}
                                    valueofTab={valueofTab}
                                    setvalueOfActiveCalender={setvalueOfActiveCalender}
                                    valueOfActiveCalender={valueOfActiveCalender}
                                    employeeData1={employeeData1}
                                />
                                {activeCalenderName != undefined &&
                                    <Timelinerevview activeCalenderName={activeCalenderName} />
                                }
                            </div>
                            <div>
                                {talentCategoreyData &&
                                    <PerformanceRatingandEmpRejectionofOverallDashboard
                                        LOCATION_FROM={LOCATION_FROM}
                                        checkAppraisalStatusC={checkAppraisalStatusC}
                                        checkAppraisalStatusIR={checkAppraisalStatusI}
                                        checkAppraisalStatusIM={checkAppraisalStatusIM}
                                        checklengthinRanges={checklengthinRanges}
                                        completedCount={checkAppraisalStatusCompletedMyteam("completed")}
                                        inprogressCount={checkAppraisalStatusInProgressMyteam("rejected")}
                                        inMediaton={checkAppraisalinmediationStatusMyteam("rejected")}
                                        range1={range1Myteam}
                                        range2={range2Myteam}
                                        range3={range3Myteam}
                                        range4={range4Myteam}
                                        TotalEmp={TotalEmpMyteam}
                                        CompletedEmp={CompletedEmpMyteam}
                                        navigationFrom={navigationFrom}
                                        handleOnClickCard={handleOnClickCardMyteam}
                                        StatusValues={StatusValuesMyteam}
                                        setselectedRatingRange={setselectedRatingRange}
                                        setRatingsExpandActive={setRatingsExpandActive}
                                    />}
                            </div>
                            <div ref={myRef} style={{ marginTop: "25px" }}>
                                <LineManagersTeamTable
                                    CalenderName={CalenderName}
                                    page={page}
                                    setPage={setPage}
                                    Role={Role}
                                    navigationFrom={navigationFrom}
                                    SectionFilter={SectionFilter}
                                    setSectionFilter={setSectionFilter}
                                    subSectionFilter={subSectionFilter}
                                    setSubSectionFilter={setSubSectionFilter}
                                    employeeData1={talentCategoreyData}
                                    valueOfCard={valueOfCardMyteam}
                                    setTeamtableExpandActive={setTeamtableExpandActive}
                                />
                            </div>
                            <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                                <NineboxandTopPerformanceofNormalizerOverallDashboard
                                    NAVIGATION_FROM={NAVIGATION_FROM}
                                    NineBoxValues={NineBoxValues}
                                    NineBoxData={NineBoxValues}
                                    topPerformerEmployees={TopPerformersOfAppraiserMyteam}
                                    navigationFrom={navigationFrom}
                                    setTopperformersExpandActive={setTopperformersExpandActive}
                                    setNineboxExpand={setNineboxExpand}
                                    indexBasedValue={indexBasedValue}
                                    setindexBasedValue={setindexBasedValue}
                                    indexBasedTitle={indexBasedTitle}
                                    setindexBasedTitle={setindexBasedTitle}
                                    setSolidperformersExpandActive={setSolidperformersExpandActive}
                                />
                            </div>
                        </div>}
                    {RatingsExpandActive && <div>
                        <GlobechartParentforoverallDashboard
                            FinalData={talentCategoreyData}
                            selectedRatingRange={selectedRatingRange}
                            setselectedRatingRange={setselectedRatingRange}
                            setRatingsExpandActive={setRatingsExpandActive}
                        />
                    </div>}
                    {TopperformersExpandActive && <div>
                        <TopPerformersparentforOverallDashboard
                            LOCATION_FROM={LOCATION_FROM}
                            topPerformerEmployeeso={TopPerformersOfAppraiserMyteam}
                            FinalData={talentCategoreyData}
                            setTopperformersExpandActive={setTopperformersExpandActive}
                        />
                    </div>}
                    {TeamtableExpandActive && <div>
                        <OverallDashboardExpandViewofRevieweroverall
                            employeeData1={talentCategoreyData}
                            setTeamtableExpandActive={setTeamtableExpandActive}
                        />
                    </div>}
                    {NineboxExpand && <div>
                        <ExpandNineBoxandSolidtalentsofOverallDashboard
                            navigationFrom={navigationFrom}
                            setNineboxExpand={setNineboxExpand}
                            setSolidperformersExpandActive={setSolidperformersExpandActive}
                            NineBoxData={NineBoxValues}
                            indexBasedTitle={indexBasedTitle}
                            setindexBasedTitle={setindexBasedTitle}
                            indexBasedValue={indexBasedValue}
                            setindexBasedValue={setindexBasedValue}
                        />
                    </div>}
                    {solidperformersExpandActive && <div>
                        <NineboxparentforOveralldashboard
                            navigationFrom={navigationFrom}
                            setSolidperformersExpandActive={setSolidperformersExpandActive}
                            indexBasedTitle={indexBasedTitle}
                            indexBasedValue={indexBasedValue}
                            NineBoxData={NineBoxValues}
                            employeeData1={talentCategoreyData}
                        />
                    </div>}
                </AppraiserDashboardContext>
            </div>
        </>
    )
}

export default OverallDashboardForReviewer